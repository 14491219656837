import {EServerMode} from "./socket.data";
import {Unit} from "./unit.data";
import {UserSettingsAsAdminRequest} from "./user.data";

export interface ProtocolHistory {
  data: Array<ProtocolEntry>;
  length: number;
  totalElements: number;
  totalPages: number;
  currentPage: number;
}

export interface ProtocolEntry {
  message: string;
  info: string;
  timestamp: number;
  source: EProtocolSource;
  hasAdditionalInformation: boolean;
  id: string;
}

export interface RestProtocolSlice {
  content: RestProtocolSimple[];
  size: number;
  first: boolean,
  last: boolean,
  numberOfElements: number,
  number: number;
}

export interface RestProtocolSimple {
  id: string;
  method: string;
  url: string;
  sourceIP: string;
  responseStatus: number;
  timestamp: number;
  loginName: string;
}

class UserTokenInfo {
  userId: string;
  personId: string;
  loggedInFromAdmin: Boolean;
  tokenExpiration: Date;
  hasWeakPW: Boolean;
  loginName: string;
  userName: string;
  personName: string;
  loginType: string;
  passwordSource: string;
}

export interface RestProtocol {
  id: string;
  method: string;
  url: string;
  sourceIP: string;
  remoteAddr: string;
  responseStatus: number;
  timestamp: number;

  requestHeaders: Map<string, string>;
  requestParams: Map<string, string>;
  requestBody: string;

  responseHeaders: Map<string, string>;
  responseBody: string;

  timeToLive: Date;

  userInfo: UserTokenInfo;

}

export interface VehicleSwapEntry {
  timestamp: number;
  user: string;
  sourceVehicleId: string;
  sourceVehicleName: string;
  destVehicleId: string;
  destVehicleName: string;
  message: string;
}

export interface VehicleSwapHistory {
  data: Array<VehicleSwapEntry>;
  length: number;
  totalElements: number;
  totalPages: number;
  currentPage: number;
}

export interface ProtocolEntryAdditionalInformation {
  content: string;
}


export interface Relais {
  id: string;
  name: string;
  internalName: string;
  port: number;
  group: ERelaisGroup;
  state: ERelaisState;
  type: ERelaisType;
  impulsTime: number;
  licenced: boolean;
}

export enum ERelaisType {
  STATE = 'STATE',
  IMPULS = 'IMPULS'
}


export enum ERelaisState {
  ON = 'ON',
  OFF = 'OFF'
}

export enum ERelaisGroup {
  SPEAKER = 'SPEAKER',
  LIGHT = 'LIGHT',
  MISC = 'MISC',
  RESERVED = 'RESERVED',
  GATE = 'GATE',
  SECURITY = 'SECURITY'
}

export enum EProtocolSource {
  ALARM = 'ALARM',
  FE2 = 'FE2',
  LOGIN = 'LOGIN',
  LOGIN_FAILED = 'LOGIN_FAILED',
  PIPELINE_EDIT = 'PIPELINE_EDIT',
  USER_ADD = 'USER_ADD',
  USER_DELETE = 'USER_DELETE',
  UNIT = 'UNIT',
  UNIT_ADD = 'UNIT_ADD',
  UNIT_DELETE = 'UNIT_DELETE',
  INPUT = 'INPUT',
  REVISION = 'REVISION',
  EDIT = 'EDIT',
  ROLE = 'ROLE',
  VEHICLES = 'VEHICLES',
  PERSON_SHARE = 'PERSON_SHARE',
  ADDRESSBOOK = 'ADDRESSBOOK',
  OBJECTS = 'OBJECTS',
  ROADBLOCK = 'ROADBLOCK',
  LAYER = 'LAYER',
  TEMPLATE = 'TEMPLATE',
  IOPRINT = 'IOPRINT',
  IOPRINT_ERROR = 'IOPRINT_ERROR',
  EXPORT_PDF = 'EXPORT_PDF',
  RELAIS_ON = 'RELAIS_ON',
  RELAIS_OFF = 'RELAIS_OFF',
  SYNC_OBJECTS = 'SYNC_OBJECTS',
  SYNC_ROADBLOCKS = 'SYNC_ROADBLOCKS',
  MESSAGE = 'MESSAGE',
  MISSION = 'MISSION',
  QUEUE = 'QUEUE'
}

export enum ELogLevel {
  TRACE = 'TRACE',
  DEBUG = 'DEBUG',
  INFO = 'INFO',
  WARN = 'WARN',
  ERROR = 'ERROR'
}

export interface MapsUsage {
  limit: number,
  used: number,
  remaining: number
}

export interface SmsUsagePerUser {
  user: string;
  months: number[];
}

export interface SmsUsage {
  year: number;
  availableCredits: number;
  users: SmsUsagePerUser[];
}

export interface UserAdminContext {
  name: string;
  organisation: string;
  id: string;
  has2FA: boolean;
  admin: boolean;
  hasOnlineService: boolean;
  onlineServiceName: string;
  hasOrganisationAdmin: boolean;
  organisationsAdmins: string[];
  blocked: boolean;
}

export interface User {
  name: string;
  id: string;
  admin: boolean;
  licenceDetails: LicenceDetailsProUser;
  hasOnlineService: boolean;
  onlineServiceName: string;
  licenseType: string;
  settings: UserSettingsAsAdminRequest;
  allowedIPRange: string;
  externalShortIdentifier: string;
}

export interface LicenceDetailsProUser {
  nbrOfPersons: number;
  nbrOfAM3s: number;
  nbrOfAMLs: number;
  nbrOfObjects: number;
  nbrOfDangerousGoods: number;
  nbrOfDangerousSubstances: number;
  nbrOfPatientManagement: number;
  nbrOfAirRescue: number;
  nbrOfHereMaps: number;
  nbrOfMapTrip: number;
  nbrOfKohlhammer: number;
  nbrOfVehicleCards: number;
  nbrOfLocationMaps: number;
  nbrOfExpertWeather: number;
}

export interface UpdateUserRequest {
  name: string;
}

export interface OrganisationLicenceSetAndAvailableData {
  userId: string;
  numbersForLicences: SingleLicenceSetAndAvailable[];
}

export interface SingleLicenceSetAndAvailable {
  usedNbrFE2: number;
  usedNbrOrganisation: number;
  availableInFE2Licence: number;
  setNbrOrganisation: number;
  licenceType: AdjustLicenceType;

}

export enum AdjustLicenceType {
  PERSONS = 'PERSONS',
  AMOBILE = 'AMOBILE',
  AM4 = 'AM4',
  OBJECTS = 'OBJECTS',
  DANGEROUS_GOODS = 'DANGEROUS_GOODS',
  DANGEROUS_SUBSTANCES = 'DANGEROUS_SUBSTANCES',
  PATIENT_MANAGEMENT = 'PATIENT_MANAGEMENT',
  AIR_RESCUE = 'AIR_RESCUE',
  HERE_MAPS = 'HERE_MAPS',
  MAP_TRIP = 'MAP_TRIP',
  KOHLHAMMER = 'KOHLHAMMER',
  VEHICLE_CARDS = 'VEHICLE_CARDS',
  LOCATION_MAPS = 'LOCATION_MAPS',
  EXPERT_WEATHER = 'EXPERT_WEATHER'
}

export enum EProvider {
  Google = 'Google',
  Azure = 'Azure',
  User = 'User'
}

export enum ESslConfiguration {
  NO = 'NO',
  STARTTLS = 'STARTTLS',
  SSL = 'SSL'
}

export interface UpdateOrganisationLicenseRequest {
  newLicenceDetailsForUser: LicenceDetailsProUser;
}

export interface AdminAddressbookResponse {
  data: DatabaseAdminPersonResponse[],
  totalElements: number,
  totalPages: number,
  currentPage: number;
}

export interface DatabaseAdminPersonResponse {
  displayName: string,
  personID: string,
  user: string,
  note: string
}

export interface DuplicateGlobalSettings {
  defaultTime: number,
  useText: boolean;
  ignoreUpdatedAlarms: boolean
}

export interface BlockedAlarmsPaginated {
  hasNext: boolean;
  hasPrevious: boolean;
  data: BlockedAlarms[];
}

export interface BlockedAlarms {
  date: string;
  organisation: string;
  organisationId: string;
  unitName: string;
  unitCode: string;
  externalId: string;
  blockedReasonType: EBlockedReasonType;
  additionalInfo: string;
}

export enum EBlockedReasonType {
  DOUBLE_ALARM = 'DOUBLE_ALARM',
  UNIT_DEACTIVATED = 'UNIT_DEACTIVATED',
  NO_PIPELINE = 'NO_PIPELINE',
  REVISION_MODE = 'REVISION_MODE',
  INVALID_LICENCE = 'INVALID_LICENCE',
  UNIT_NOT_FOUND = 'UNIT_NOT_FOUND',
  TOO_MANY_ALARMS = 'TOO_MANY_ALARMS',
  EXCEPTION = 'EXCEPTION',
  AGE_CHECK = 'AGE_CHECK',
  DEFAULT_UNIT_NOT_SELECTED = 'DEFAULT_UNIT_NOT_SELECTED',
  UNIT_NOT_ALLOWED = 'UNIT_NOT_ALLOWED'
}

export interface CreateOrganisationRequest {
  name: string,
  password: string,
  createOnlineService: boolean;
}


export interface Voice {
  name: string;
  description: string;
  gender: string;
  culture: string;
  version: string;
}

export interface MqttSetting {
  name: string;
}

export interface SmsEagleSignal {
  current: number;
  min: number;
}

export interface ConfigStatusResponse {
  configurationSet: boolean;
}

export interface SmsEagleSettings {
  url: string;
  authKey: string;
  minSignal: number;
  valid: boolean;
}

export interface SmsEagleQueueLength {
  length: number;
}

export interface RestProtocolSettings {
  savingActive: boolean;
  cleaningActive: boolean;
  interval: number;
}

export interface SSOSettings {
  useSingleSignOn: boolean;
  tenant: string;
  apiClientId: string;
  objectId: string;
  clientSecretValue: string;
  useScim: boolean;
  scimApiKey: string;
  blocked: boolean;
  ssoProvider: ESSOProvider;
  authorizationServerId: string; // server id used in okta, path variable according to documentation, dev account doesn't have this

}

export enum ESSOProvider {
  AZURE = 'AZURE', OKTA = 'OKTA'
}

export interface SsoOnResponse {
  useSingleSignOn: boolean;
}

export interface SsoBlockedResponse {
  blocked: boolean;
}

export interface MiscSettings {
  resetTimeStatus5: number;
  resetTimeStatusC: number;
  resetTimeSirenTriggered: number;
  massAlertTimeInSeconds: number;
  massAlertSeparator: string;
  massAlertResetAfterEveryAlert: boolean;
  massAlertCollectFeedbacks: boolean;
  expressAlertTimeInSeconds: number;
  expressAlertSeparator: string;
  expressAlertDontWaitForText: boolean;
  expressAlertCancelIfTextNotAvailable: boolean;
  allowAllUsersToAccessAllUnits: boolean;
  shareAdminAddressbook: boolean;
  shareAdminRoles: boolean;
  shareImageAssets: boolean;
  sharePluginTemplates: boolean;
  enablePersonShareFromUserContext: boolean;
  deleteRoadblocks: boolean;
  enforceCustomerSmsKey: boolean;
  enableAutoUpdate: boolean;
  shareFeedbackForAlarmUpdates: boolean;
  ttsSpeed: number;
  voice?: Voice;
  allVoices: Voice[];
  wachalarmSource: string;
  mqttBroker: MQTTBroker;
  personDuplicateAlarmActive: boolean;
  personDuplicateAlarmTimeInHours: number;
  shareStatusDuringMission: boolean;
  shareObjectsOnAlarm: boolean;
  shareChatrooms: boolean;
  kbaRuleValidation: ERuleValidation;
  enableCleanLogs: boolean;
  cleanLogsTime: number;
  queueCloseOnFinalReport: boolean;
  queueFinishMode: EFinishMode;
  supportHint: string;
}

export interface StatusSettings {
  statusCleanEnabled: boolean;
  statusCleanPeriod: number;
  changeToStatusCEnabled: boolean;
  removeResourceEnabled: boolean;
}

export enum EFinishMode {
  FINISH_SINGLE_ENTRY = 'FINISH_SINGLE_ENTRY',
  FINISH_ALL_RUNNING_ENTRIES = 'FINISH_ALL_RUNNING_ENTRIES',
  FINISH_ALL_ENTRIES = 'FINISH_ALL_ENTRIES'
}


export enum ERuleValidation {
  AUTO = 'AUTO',
  AUTO_WITH_APPROVAL = 'AUTO_WITH_APPROVAL'
}

export enum MQTTBroker {
  CLUSTER = 'CLUSTER', LEGACY = 'LEGACY'
}


export interface GeneralResponse {
  message: string;
}

export interface QrCodeResponse {
  qrCode: string;
}

export interface WebserverSettings {
  title: string;
  port: number;
  externalAddress: string;
  externalAddressAMweb: string;
  use2fa: boolean;
  useSsl: boolean;
  hostnameValidation: boolean;
  expiryDuration: number;
  restrictedIps: string;
  handleIpsMode: string;
  keystore: string;
  password: string;
  securityTxtContact: string;
  attackByHostsIndicator: number;
  attackByLoginsIndicator: number;
  loginAttackNotificationPeriod: number;
  amwebAuthorization: string;
  amwebAuthorizationActive: boolean;
  amwebHost: string;
}

export interface MasterSlaveSettings {
  mode: EServerMode;
  masterIp: string;
  masterPort: number;
  allowSelfSigned: boolean;
  interval: number;
  useGlobalProxy: boolean;
  hasMonitoringData: boolean;
  masterMonitoringData: MonitoringData;
  lastMonitoringData: string;
}

export interface MonitoringData {
  cloudStates: CloudState[];
  mqttState: MqttState;
  databaseStatus: EFe2Status;
  hardwareState: HardwareState;
  systemState: SystemState;
}

export interface CloudState {
  nbrOfFailedRequests: number;
  state: EMonitoringState;
  service: string;
}

export interface HardwareState {
  smallestFreeDiskSpaceInGb: number;
  smallestDiskName: number;
  disks: DiskState[];
}

export interface SystemState {
  state: EMonitoringState;
  message: string;
  redundancyState: RedundancyState;
}

export interface RedundancyState {
  state: EMonitoringState;
  current: EServerMode;
  configured: EServerMode;
}

export interface DiskState {
  disk: string;
  freeSpace: number;
}

export enum EMonitoringState {
  OK = "OK",
  ERROR = "ERROR",
  WARN = "WARN",
  NOT_USED = "NOT_USED"
}
export enum EFe2Status {
  OK = "OK",
  NOT_OK = "NOT_OK",
  WARNING = "WARNING"
}

export interface MqttState {
  defaultBroker: EMonitoringState;
  kubernetes: EMonitoringState;
  nbrOfFailedChecks: number;
}

export interface MailAccountSettings {
  server: string;
  username: string;
  sender: string;
  alias: string;
  password: string;
  auth: boolean;
  sslMode: EMailAccountSslMode;
  port: number;
  receiver: string[];
  smimeSigningPath: string;
  smimeSigningPassword: string;
  emailTemplateImgPath: string;
  provider: EProvider;
}

export enum EMailAccountSslMode {
  UNDEFINED = 'UNDEFINED',
  NO = 'NO',
  STARTTLS = 'STARTTLS',
  SSL_TLS = 'SSL_TLS'
}

export interface OutputPluginSettings {
  first: Map<string, DeactivatedPluginListEntry>;
  second: Map<string, DeactivatedPluginListEntry>;
}

export interface DeactivatedPluginListEntry {
  className: string;
  deactivated: boolean;
}

export interface KeywordSettings {
  field: string;
  matchingActive: boolean;
  overwriteKeywordMode: OverwriteKeywordMode;
}

export enum OverwriteKeywordMode {
  NONE = 'NONE',
  OVERWRITE = 'OVERWRITE',
  ADD = 'ADD'
}

export interface ReplacementSettings {
  data: string;
  trimWhitespace: boolean;
}

export interface OnlineServiceMapping {
  id: number;
  idToDisplay: string;
  inUse: boolean;
  name: string;
}

export interface UpdateOnlineServiceMapping {
  userId: string;
  osId: number;
}

export interface OutageSettings {
  hasMailAccount: boolean;
  outageActive: boolean;
  dailyMailActive: boolean;
  warningActive: boolean;
  environmentSettings: boolean;
  dailyMailTime: string;
  outageId: string;
  outageName: string;
  outageSystems: OutageSystem[];
}

export interface OutageSystem {
  name: string;
  id: string;
}

export interface FeedbackUnitGroupSimple {
  name: string;
  id: string;
  delayTime: number;
  considerExternalId: boolean;
}

export interface FeedbackGroupsUnits {
  id: string;
  units: Unit[];
}

export interface AlarmSplitterConfig {
  splitters: SplitterData[],
  waitTime: number,
  postWaitTime: number
}

export interface SplitterData {
  codeFor: string,
  codeFrom: string,
  codeTo: string
}

export interface SimpleUnit {
  id: string,
  code: string,
  name: string,
  parentName: string
}

export interface AddressSettings {
  digitsCoordinate: number;
  longitudeBeforeLatitude: boolean;
  positionMode: number;
  notFoundMode: number;
  useRegardless: boolean;
  lookForBuildingsWithStreetOnly: boolean;
  ignoreIfBuildingWasFound: boolean;
  checkSurroundingObjects: boolean;
  checkSurroundingObjectsRadius: number;
  checkOnlyExactObjectName: boolean;
  findDirectionCardWithoutObject: boolean;
}

export interface AdminAlarmObjectsResponse {
  alarmObjects: AdminAlarmObject[];
  totalElements: number;
  totalPages: number;
  currentPage: number;
}

export interface AdminAlarmObject {
  abbreviation: string
  street: string;
  city: string;
  house: string;
  postalCode: string;
  additional: string;
  building: string;
  lat: number;
  lng: number;
}

export interface AdminStreetsResponse {
  streets: AdminStreet[];
  totalElements: number;
  totalPages: number;
  currentPage: number;

}

export interface AdminStreet {
  postalCode: string;
  abbreviation: string
  city: string
  street: string
}

export interface ComingHomeSettings {
  status97Mode: EStatus97Mode;
  comingHomeStatus1: boolean;
  comingHomeStatus2: boolean;
  comingHomeStatus3: boolean;
  comingHomeStatus4: boolean;
  comingHomeStatus5: boolean;
  comingHomeStatus6: boolean;
  comingHomeStatus7: boolean;
  comingHomeStatus8: boolean;
  comingHomeStatus9: boolean;
}

export enum EStatus97Mode {
  COMING_HOME = 'COMING_HOME',
  OPEN_GATE = 'OPEN_GATE'
}

export interface LicenceDistribution {
  licenceType: AdjustLicenceType;
  organisationLicences: UserLicenceResponse[];
}

export interface UserLicenceResponse {
  user: string;
  used: number;
  assigned: number;
}


export interface MqttStatus {
  connected: boolean;
  fromInputPlugin: boolean;
  broker: string;
  connectionName: string;
  key: string;
  hasFallback: boolean;
  fallbackConnected: boolean;
  fallbackBroker: string;
  deliveredMessages: number;
  deliveryFailedMessages: number;
  deliveredFallbackMessages: number;
  deliveryFailedFallbackMessages: number;
  receivedMessages: number;

  atLeastOnSuccessfullyConnect: boolean;
  atLeastOnSuccessfullyFallbackConnect: boolean;
}

export interface HttpMetrics {
  host: string;
  okCounter: number;
  failedCounter: number;
  state: HttpMetricState;
}

export enum HttpMetricState {
  OK = 'OK',
  ALL_FAILED = 'ALL_FAILED',
  RECENTLY_FAILED = 'RECENTLY_FAILED'
}

export interface ConnectionResponse {
  creationTime: String;
  lastChange: String;
  lastChangeSpan: String;
  source: EConnectionSource;
  sourceColor: string;
  hashId: number;
  shortId: String;
  name: String;
  connected: boolean;
  connectionInfo: string;
}

export interface BackendFallbackInfo {
  fallbackMode: BackendFallbackMode,
  ingresses: BackendIngress[]
}

export enum BackendFallbackMode {
  DISABLED = "DISABLED",
  FALLBACK = "FALLBACK",
  RANDOM = "RANDOM"
}

export interface BackendIngress {
  host: string,
  state: "UP" | "DOWN" | "LOADING",
  lastDowntime?: string
}

export enum EConnectionSource {
  GRCP = 'GRCP',
  TCP = 'TCP',
  COM = 'COM',
  WS = 'WS',
  HTTP = 'HTTP'
}

export interface CustomerNotificationResponse {
  title: string;
  message: string;
  created: string;
  validFrom: string;
  validTill: string;
  lastChanged: string;
  link?: string;
  criticality: Criticality;
  color: string;
  id: string;
  internalNote: string;
  triggerApager: boolean;
}

export enum Criticality {
  LOW = 'LOW',
  MEDIUM = 'MEDIUM',
  HIGH = 'HIGH',
  CRITICAL = 'CRITICAL'
}

export interface KbaResponse {
  hasNext: boolean;
  hasPrevious: boolean;
  page: number;
  data: KbaRequest[];
}

export interface KbaRequest {
  amobileName: string;
  externalId: string;
  location: string;
  id: string;
  licencePlate: string;
  sourceIp: string;
  userId: string;
  vehicleId: string;
  vehicleName: string;
  vehicleStatus: string;
  userName: string;
  vehicleStatusFontColor: string;
  vehicleStatusColor: string;
  vehicleStatusSource: string;
  history: KbaRequestHistory[];
  state: EKbaRequestState;
  result: RescueCardResult;
  anonymizationTimestamp: string;
  anonymized: boolean;
  validationResult?: ValidationResult;
  externalFE2: boolean;
}

export interface ValidationResult {
  ruleHasAMobile: boolean;
  ruleHasConnectedVehicle: boolean;
  ruleHasMission: boolean;
  ruleHasCorrectStatus: boolean;
  ruleHasCorrectStatusSource: boolean;
}

export interface KbaRequestHistory {
  timestamp: string;
  state: EKbaRequestState;
  message: string;
  anonymizationTimestamp: string;
  anonymized: boolean;
}

export interface RescueCardResult {
  schluesselHersteller: string;
  schluesselTyp: string;
  schluesselVarianteVersion: string;
  textHersteller: string;
  textMarke: string;
  textHandelsbezeichnung: string;
  textTyp: string;
  textVariante: string;
  textVersion: string;
  schluesselKraftstoff: string;
  textKraftstoff: string;
  anzahlSitzplaetze: string;
  technischZulaessigeGesamtmasse: string;
  datumErstzulassungJahr: number;
}

export enum EKbaRequestState {
  CREATED = 'CREATED',
  PENDING = 'PENDING',
  PENDING_MANUAL_APPROVAL = 'PENDING_MANUAL_APPROVAL',
  RULE_APPROVAL = 'RULE_APPROVAL',
  MANUAL_APPROVED = 'MANUAL_APPROVED',
  APPROVED = 'APPROVED',
  DECLINED = 'DECLINED',
  SUBMITTED = 'SUBMITTED',
  SUCCESS = 'SUCCESS',
  FAILURE = 'FAILURE',
  PENDING_EXTERNAL_APPROVAL = 'PENDING_EXTERNAL_APPROVAL'
}

export interface ApproveKbaRequest {
  approve: boolean;
  message: string;
}

export interface KbaRequestEvent {
  requestId: string;
}

export interface AdminMapSettingsResponse {
  mapTripUser: string;
  mapTripPassword: string;
}

export interface RoutingSettings {
  provider: ERoutingServiceProvider;
  hasRoutingService: boolean;
  metrics: RouteMetric[];
  status: RoutingServiceStatus;
  settings: Map<String, String>;
  elementsInCache: number;
}

export interface RouteMetric {
  key: string;
  value: number;
}

export interface RoutingServiceStatus {
  version: string;
  msg: String;
  status: EServiceStatus;
}

export enum EServiceStatus {
  ONLINE = 'ONLINE',
  OFFLINE = 'OFFLINE',
  NOT_CONFIGURED = 'NOT_CONFIGURED',
  UNKNOWN = 'UNKNOWN'
}

export enum ERoutingServiceProvider {
  ARCGIS_ENTERPRISE = 'ARCGIS_ENTERPRISE',
  ALAMOS = 'ALAMOS',
  VALHALLA = 'VALHALLA',
  NONE = 'NONE'
}

export interface DryadDeviceResponse {
  eui: string;
  name: string;
  lastSensorUpdate: string;
  creationTime: string;
  site: string;
  online: boolean;
}
